<template>
  <div class="d-flex flex-column flex-root">
    <!-- begin::Header Mobile -->
    <KTHeaderMobile ref="KTHeaderMobile">
      <template v-slot:logo>
        <router-link to="/">
          <img :src="require('@/assets/images/logo/myluxura-logo.light.svg')" alt="Logo" class="h-40px" />
        </router-link>
      </template>
    </KTHeaderMobile>
    <!-- end::Header Mobile -->

    <!-- begin::Body -->
    <div class="d-flex flex-row flex-column-fluid page">
      <!-- begin::Aside Left -->
      <KTAside v-if="asideEnabled">
        <template v-slot:brand>
          <KTBrand ref="brand">
            <template v-slot:default>
              <router-link to="/">
                <img :src="require('@/assets/images/logo/myluxura-logo.light.svg')" alt="Logo" class="mt-5 w-175px mx-auto" />
              </router-link>
            </template>

            <template v-slot:toggle>
              <span class="svg-icon svg-icon svg-icon-xl">

              </span>
            </template>
          </KTBrand>
        </template>

        <template v-slot:menu>
          <KTMenuAside
            ref="KTMenuAside"
            :company="company"
          />
        </template>
      </KTAside>
      <!-- end::Aside Left -->

      <!-- begin::Wrapper -->
      <div class="d-flex flex-column flex-row-fluid wrapper">
        <!-- begin:: Header -->
        <KTHeader ref="KTHeader">
          <template v-slot:menu>
            <KTMenu
              ref="KTMenu"
              :company="company"
            />
          </template>

          <template v-slot:topbar>
            <KTTopbar ref="KTTopbar" :company="company" />
          </template>
        </KTHeader>
        <!-- end:: Header -->

        <!-- begin:: Content -->
        <div class="content d-flex flex-column flex-column-fluid">
          <!-- begin:: Content Head -->
          <KTSubheader
            v-if="subheaderDisplay"
            :breadcrumbs="breadcrumbs"
            :title="pageTitle"
          />
          <!-- end:: Content Head -->

          <!-- begin::Content Body -->
          <div class="d-flex flex-column-fluid">
            <div :class="[(contentFluid ? 'container-fluid' : 'container')]">
              <transition name="fade-in-up">
                <template v-if="isRouteAnError">
                  <!-- Error - 404 -->
                  <not-found-error
                    v-if="getRouteError === '404'"
                    ref="notFoundError"
                    class="container"
                  />
                </template>

                <template v-else>
                  <router-view
                    ref="router"
                    v-if="company"
                  />
                </template>
              </transition>
            </div>
          </div>
          <!-- end::Content Body -->
        </div>
        <!-- end::Content -->

        <!-- begin::Footer -->
        <KTFooter ref="KTFooter" v-if="footerDisplay">
          <div class="text-dark">
            <span class="text-muted font-weight-bold mr-2">
              {{ new Date().getFullYear() }} &copy;
            </span>
            <a href="https://www.vdlhapro.com" target="_blank" class="text-dark-75 text-hover-brand">
              VDL Hapro
            </a>

            <span class="text-muted px-3">|</span>

            <a href="https://vedicium.com" target="_blank" class="text-dark-75 text-hover-brand">
              <span class="text-muted">Realised by</span> VeDicium
            </a>
          </div>
        </KTFooter>
        <!-- end::Footer -->
      </div>
      <!-- emd::Wrapper -->
    </div>
    <!-- end::Body -->

    <!-- begin::Modals -->
    <modals ref="modals" />
    <!-- end::Modals -->
  </div>
</template>

<script>
// Components
import KTMenu from '@/components/layout/header/menu.vue';
import KTTopbar from '@/components/layout/header/topbar.vue';
import KTMenuAside from '@/components/layout/aside/menu.vue';

import modals from '@/components/layout/modals/index.vue';
import { NotFoundError } from '@vedicium/metronic-vue';

import layoutConfig from '@/components/layout/config';
import errorUIMixin from '@/mixins/error.ui';
import { router as routerLib } from '@vedicium/core-vue';
import { company as companyLib, CompanyClass as Company } from '@vedicium/core-vue/build/modules/company';

export default {
  mixins: [errorUIMixin],
  name: 'layout',
  components: {
    KTMenu,
    KTTopbar,

    KTMenuAside,

    modals,
    // eslint-disable-next-line vue/no-unused-components
    NotFoundError,
  },
  data () {
    return {
      pageTitle: this.$metronic.breadcrumbs.pageTitle(),
      breadcrumbs: this.$metronic.breadcrumbs.breadcrumbs,

      company: null,
    };
  },

  computed: {
    contentFluid () {
      return this.$metronic.config.layout.get('content.width') === 'fluid';
    },
    asideEnabled () {
      return !!this.$metronic.config.layout.get('aside.self.display');
    },

    subheaderDisplay () {
      return !!this.$metronic.config.layout.get('subheader.display');
    },

    footerDisplay () {
      return !!this.$metronic.config.layout.get('footer.display');
    },

    getRouteError () {
      return routerLib.getRouteError(this.$route);
    },
    isRouteAnError () {
      return !!this.getRouteError;
    },
  },

  beforeMount () {
    // Listeners
    this.$metronic.eventhub.on('layout:breadcrumbs', this.onBreadcrumbs.bind(this));
    this.$eventhub.on('core:company:current', this.onCompanyChange.bind(this));

    // Initialize HTML classes
    this.$metronic.config.layout.setConfig(layoutConfig);
    this.$metronic.config.classes.init();
  },
  async mounted () {
    // User resource
    if (this.$auth.isResourceFromIndex('users')) {
      // Open company modal if no company set
      this.onCompanyChange(await companyLib.getSession());

      // If invite is found in query, show company-invite modal
      if (this.$route.query.invite) {
        const vm = this;
        this.$eventhub.emit('modals:layout:company-invite:open', {
          token: this.$route.query.invite,
          async onSubmit (companyInvite = null) {
            if (!companyInvite) {
              return;
            }

            if (companyInvite.response && companyInvite.response.value === 'accepted') {
              await companyLib.setCompany(companyInvite.company.guid);
              vm.$eventhub.emit('modals:layout:switch-company:close');
            }
          },
        });
      }
    }
  },
  beforeDestroy () {
    // Remove listeners
    this.$metronic.eventhub.off('layout:breadcrumbs', this.onBreadcrumbs.bind(this));
    this.$eventhub.off('core:company:current', this.onCompanyChange.bind(this));
  },

  methods: {
    onBreadcrumbs (crumbs) {
      this.$set(this, 'breadcrumbs', crumbs);
      this.$set(this, 'pageTitle', this.$metronic.breadcrumbs.pageTitle());
    },

    onCompanyChange (company, options = {}) {
      // Return function if company hasn't changed at all (if not from reconnect)
      if (company && this.company && company._meta.guid === this.company._meta.guid && options.force_update !== true) {
        return;
      }

      if (company) {
        this.$ws.put(`${Company.uri}/subscribe`);
      }

      if (!company) {
        // This check is for when the user logs out
        if (this.$auth.resource) {
          this.$eventhub.emit('modals:layout:switch-company:open', {
            required: true,
            auto_select: !this.company,
            redirect: (!this.company ? window.location.pathname : null),
          });
        }

        this.$set(this, 'company', company);
        return;
      }

      // Only set to 'null' first, when current company guid doesn't match with new company guid
      if (!company || !this.company || this.company._meta.guid !== company._meta.guid) {
        this.$set(this, 'company', null);
      }

      this.$nextTick(() => this.$set(this, 'company', company));
    },
  },
};
</script>
