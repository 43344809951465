<template>
  <b-modal
    ref="modal"

    title="Select company"

    size="lg"
    centered

    :no-close-on-esc="(options && options.required === true)"
    :no-close-on-backdrop="(options && options.required === true)"
    :hide-header-close="(options && options.required === true)"
    :hide-footer="(options && options.required === true)"
  >
    <template v-slot:default>
      <template v-if="isLoading">
        <div class="row">
          <div class="col-12 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
            <div class="loading-container">
              <div class="loading-block">
                <div class="blockui ml-auto mr-auto w-100">
                  <span class="float-left">Gathering data...</span>
                  <span class="spinner spinner-primary ml-auto" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-else>
        <div class="row" v-if="!companies || companies.length === 0">
          <div class="col-12 alert alert-custom alert-light-info my-0" role="alert">
            <div class="alert-icon">
              <i>
                <font-awesome-icon :icon="['fas', 'store-alt']" />
              </i>
            </div>
            <div class="alert-text">
              <span class="font-weight-bold d-block">No companies found</span>
              <span class="d-block">
                You don't have any companies linked to your account.
              </span>
            </div>
          </div>

          <div class="col-12 mt-5 pt-5 border-top text-right">
            <button type="button" class="btn btn-info" @click.prevent="logout">Logout</button>
          </div>
        </div>

        <div v-else class="row row-cols-1 row-cols-lg-2">
          <div v-for="(company) in companies" :key="`company.${company._meta.guid}`" class="col cursor-pointer" @click.prevent="setCompany(company)">
            <div class="card card-custom card-stretch gutter-b shadow">
              <!--begin::Body-->
              <div class="card-body d-flex">
                <div class="flex-shrink-0 mr-7 my-auto">
                  <div class="symbol symbol-50">
                    <span class="symbol-label bg-none">
                      <i class="h-100 w-100">
                        <font-awesome-icon :icon="['fas', 'store-alt']" fixed-width :class="['w-100 h-100', { 'text-brand': (currentCompany && currentCompany._meta.guid === company._meta.guid) }]" />
                      </i>
                    </span>
                  </div>
                </div>
                <div class="d-flex flex-column flex-grow-1 py-2 py-lg-5">
                  <div class="card-title font-weight-bolder text-dark-75 font-size-h5 my-auto">{{ company.name }}</div>
                  <span v-if="company.location && company.location.description" class="font-weight-bold text-muted font-size-lg">{{ company.location.description }}</span>
                </div>
              </div>
              <!--end::Body-->
            </div>
          </div>
        </div>
      </template>
    </template>

    <template v-slot:modal-footer>
      <button type="button" class="btn btn-secondary" @click.prevent="close">Cancel</button>
    </template>
  </b-modal>
</template>

<script>
import { Mixins } from '@vedicium/metronic-vue';
import { CompanyClass as Company, company as companyLib } from '@vedicium/core-vue/build/modules/company';

export default {
  mixins: [Mixins.Modal],
  name: 'switchCompanyModal',
  data () {
    return {
      options: null,
      isLoading: false,

      companies: [],
      currentCompany: null,
    };
  },

  mounted () {
    // Listeners
    this.$eventhub.on('modals:layout:switch-company:open', this.open);
    this.$eventhub.on('modals:layout:switch-company:close', this.close);

    // Get companies of user
  },
  beforeDestroy () {
    // Remove listeners
    this.$eventhub.off('modals:layout:switch-company:open', this.open);
    this.$eventhub.off('modals:layout:switch-company:close', this.close);
  },

  methods: {
    async getCompanies (options = {}) {
      this.$set(this, 'isLoading', true);
      this.$set(this, 'companies', []);

      try {
        const companies = await this.$ws.get(`${Company.uri}/resource`, {}, { skip_companies: true });
        this.$set(this, 'companies', companies.map((company) => new Company().merge(company)));
      } catch (e) {
        console.error(e);
        this.$set(this, 'isLoading', false);
        return this.companies;
      }

      // If only one company is found, select that one
      if (options && options.auto_select === true) {
        if (this.companies && this.companies.length === 1) {
          this.setCompany(this.companies[0]);
        }
      }

      this.$set(this, 'isLoading', false);
      return this.companies;
    },

    async setCompany (company) {
      if (!company || company instanceof Company === false) {
        return;
      }

      await companyLib.setCompany(company._meta.guid);

      const redirectPath = this.options.redirect || '/dashboard';
      if (this.$route.path !== redirectPath) {
        this.$router.push(redirectPath);
      }

      this.close();
    },

    async logout () {
      try {
        await this.$auth.logout();
      } catch (e) {
        this.$errors.handle(e);
        console.error(e);
        return;
      }

      this.$router.push({ path: '/login' });
      this.close();
    },

    open (options = {}) {
      // Reset options
      this.$set(this, 'options', options);
      this.$set(this, 'companies', []);
      this.$set(this, 'currentCompany', companyLib.current);

      // Get companies
      this.getCompanies({
        auto_select: (this.options.required === true && this.options.auto_select !== false),
      });

      this.$refs.modal.show();
    },
    close () {
      this.$refs.modal.hide();
    },

    onShown () {

    },
  },
};
</script>
