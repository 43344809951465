<template>
  <!-- begin:: Header Topbar -->
  <div class="topbar">
    <!-- begin::Company -->
    <div v-if="company" class="topbar-item mr-3">
      <button class="btn btn-brand h-35px" @click.prevent="openCompanyModal">
        <span class="svg-icon align-middle pr-1">
          <font-awesome-icon :icon="['fas', 'store-alt']" />
        </span>
        <span>{{ (company && company.name) }}</span>
      </button>
    </div>
    <!-- end::Company -->

    <!--begin: User Bar -->
    <b-dropdown
      ref="userDropdown"
      size="md"
      variant="link"
      toggle-class="topbar-item text-decoration-none dropdown-menu-top-unround"
      no-caret
      right
      no-flip
      lazy
      class="dropdown-menu-top-unround"
    >
      <template v-slot:button-content>
        <div class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-md-2">
          <span
            class="text-dark-50 font-weight-bold font-size-base d-none d-md-inline mr-3"
          >
            {{ ($auth.resource ? ($auth.resource.name || $auth.resource.email) : '') }}
          </span>
          <span class="symbol symbol-35 symbol-brand">
            <span class="symbol-label font-size-h5 font-weight-bold">
              <template v-if="$auth.resource && $auth.resource.initials">{{ $auth.resource.initials }}</template>
              <template v-else>
                <font-awesome-icon :icon="['fas', 'user']" />
              </template>
            </span>
          </span>
        </div>
      </template>
      <b-dropdown-text tag="div" class="min-w-md-250px dropdown-menu-top-unround">
        <!--begin::Summary-->
        <ul class="navi navi-hover navi-accent navi-active navi-dark py-4">
          <li class="navi-item navi-item-active">
            <router-link
              to="/settings/personal"
              class="navi-link"
              active-class="active"
            >
              <span class="symbol symbol-20 mr-3">
                <font-awesome-icon :icon="['fas', 'cog']" />
              </span>
              <span class="navi-text">Personal settings</span>
            </router-link>
          </li>
        </ul>
        <!--end::Summary-->

        <div class="border-top p-5">
          <div class="col-12">
            <button class="btn btn-brand btn-block" @click.prevent="logout">Logout</button>
          </div>
        </div>
      </b-dropdown-text>
    </b-dropdown>
    <!--end: User Bar -->
  </div>
  <!-- end:: Header Topbar -->
</template>

<style lang="scss">
.topbar {
  .dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
import { CompanyClass as Company } from '@vedicium/core-vue/build/modules/company';

export default {
  name: 'KTTopbar',
  props: {
    company: {
      type: Company,
      default: null,
    },
  },
  watch: {
    $route () {
      if (!this.$refs.userDropdown) {
        return;
      }

      if (this.$refs.userDropdown.visible === true) {
        this.$refs.userDropdown.hide();
      }
    },
  },
  methods: {
    openCompanyModal () {
      this.$eventhub.emit('modals:layout:switch-company:open');
    },

    async logout () {
      try {
        await this.$auth.logout();
      } catch (e) {
        this.$errors.showModal(e);
        return;
      }

      this.$router.push({ path: '/login' });
    },
  },
};
</script>
