import i18n from '@/i18n/index';

import { errorHandling, errorUIMixin } from '@vedicium/core-vue';

export default {
  mixins: [errorUIMixin],
  methods: {
    clearError () {
      this.$bvToast.hide();
    },

    handleError (error, options) {
      if (options.ui_element !== false) {
        const convertedError = errorHandling.convert(error);

        const translation = {
          path: convertedError.i18n.path,
          title: this.$tu('errors.fallback.title', convertedError) || convertedError.error,
          message: this.$tu('errors.fallback.message', convertedError) || convertedError.message,
        };

        translation.title = i18n.tu(`${translation.path}.title`, convertedError) || translation.title;
        translation.message = i18n.tu(`${translation.path}.message`, convertedError) || translation.message;

        this.$bvToast.toast(this.$createElement('span', { domProps: { innerHTML: translation.message } }), {
          toaster: 'b-toaster-top-center',
          title: translation.title,
          variant: 'danger',
          solid: true,
        });
      }
    },
  },
};
