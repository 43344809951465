<template>
  <div>
    <switch-company-modal ref="switchCompanyModal" />
    <confirmation-modal ref="confirmationModal" />
    <information-modal ref="informationModal" />
    <company-invite-modal ref="companyInviteModal" />
    <modify-field-modal ref="modifyFieldModal" />
  </div>
</template>

<script>
import { ConfirmationModal, InformationModal } from '@vedicium/metronic-vue';
import switchCompanyModal from '@/components/layout/modals/switch-company.vue';
import companyInviteModal from '@/components/layout/modals/company-invite.vue';
import modifyFieldModal from '@/components/layout/modals/modify-field.vue';

export default {
  components: {
    switchCompanyModal,
    ConfirmationModal,
    InformationModal,
    companyInviteModal,
    modifyFieldModal,
  },
};
</script>
