<template>
  <ul class="menu-nav">
    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item"
    >
      <img :src="require('@/assets/images/logo/myluxura-logo.dark.svg')" class="img-fluid w-175px h-auto ml-n3" alt="MyLuxura" />
    </li>
  </ul>
</template>

<script>
import { CompanyClass as Company } from '@vedicium/core-vue/build/modules/company';

export default {
  name: 'KTMenu',
  props: {
    company: {
      type: Company,
      default: null,
    },
  },

  methods: {
    hasActiveChildren (match) {
      return this.$route.path.indexOf(match) !== -1;
    },

    openCompanyModal () {
      this.$eventhub.emit('modals:layout:switch-company:open');
    },
  },
};
</script>
