var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"menu-nav"},[(_vm.$auth.isResourceFromIndex('users'))?[(_vm.company)?[_c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text text-white"},[_vm._v(_vm._s(_vm.company.name))]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]),_c('router-link',{attrs:{"to":"/dashboard","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'home']}})],1),_c('span',{staticClass:"menu-text"},[_vm._v("Dashboard")])])])]}}],null,false,2066319622)}),(_vm.$auth.hasPermission('equipment.get'))?_c('router-link',{attrs:{"to":"/sunbeds","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var href = ref.href;
          var navigate = ref.navigate;
          var isActive = ref.isActive;
          var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'tags']}})],1),_c('span',{staticClass:"menu-text"},[_vm._v("Sunbeds")])])])]}}],null,false,4202323444)}):_vm._e(),(_vm.$auth.hasPermission('equipment.statistics.overall_errors'))?_c('router-link',{attrs:{"to":"/statistics/sunbeds/errors","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var href = ref.href;
          var navigate = ref.navigate;
          var isActive = ref.isActive;
          var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'exclamation-circle']}})],1),_c('span',{staticClass:"menu-text"},[_vm._v("Sunbed errors")])])])]}}],null,false,1224585105)}):_vm._e(),(_vm.$auth.hasPermission('companies.notifications.preferences.get') || _vm.$auth.hasPermission('companies.notifications.settings.get'))?_c('router-link',{attrs:{"to":"/notifications","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var href = ref.href;
          var navigate = ref.navigate;
          var isActive = ref.isActive;
          var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'bell']}})],1),_c('span',{staticClass:"menu-text"},[_vm._v("Notifications")])])])]}}],null,false,1943247188)}):_vm._e(),(_vm.$auth.hasPermission('users.get'))?_c('router-link',{attrs:{"to":"/users","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var href = ref.href;
          var navigate = ref.navigate;
          var isActive = ref.isActive;
          var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'users']}})],1),_c('span',{staticClass:"menu-text"},[_vm._v("Users")])])])]}}],null,false,1345928173)}):_vm._e(),(_vm.$auth.hasPermission('companies.get'))?_c('router-link',{attrs:{"to":"/companies","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var href = ref.href;
          var navigate = ref.navigate;
          var isActive = ref.isActive;
          var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'store-alt']}})],1),_c('span',{staticClass:"menu-text"},[_vm._v("Companies")])])])]}}],null,false,3895932295)}):_vm._e(),_c('router-link',{attrs:{"to":"/settings/company","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var href = ref.href;
          var navigate = ref.navigate;
          var isActive = ref.isActive;
          var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'cogs']}})],1),_c('span',{staticClass:"menu-text"},[_vm._v("Settings")])])])]}}],null,false,3975157232)}),(_vm.company && (_vm.$auth.hasPermission('updates.get') || _vm.$auth.hasPermission('devices.get')))?[_vm._m(0),(_vm.$auth.hasPermission('updates.get'))?_c('router-link',{attrs:{"to":"/admin/updates","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var href = ref.href;
          var navigate = ref.navigate;
          var isActive = ref.isActive;
          var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'cloud-upload-alt']}})],1),_c('span',{staticClass:"menu-text"},[_vm._v("Updates")])])])]}}],null,false,1511838532)}):_vm._e(),(_vm.$auth.hasPermission('devices.get'))?_c('router-link',{attrs:{"to":"/admin/modules","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
            var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'cubes']}})],1),_c('span',{staticClass:"menu-text"},[_vm._v("Modules")])])])]}}],null,false,2302169670)}):_vm._e()]:_vm._e()]:_vm._e(),_vm._m(1),_c('li',{staticClass:"menu-item",attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":"https://support.vdlhapro.com/hc/en-150/categories/360003415878","target":"_blank"}},[_c('i',{staticClass:"menu-icon"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'question-circle']}})],1),_c('span',{staticClass:"menu-text"},[_vm._v("Frequently Asked Questions")])])])]:_vm._e()],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text text-white"},[_vm._v("Admin")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text text-white"},[_vm._v("Support")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})])}]

export { render, staticRenderFns }