<template>
  <ul class="menu-nav">
    <!-- begin::Resource - user -->
    <template v-if="$auth.isResourceFromIndex('users')">
      <!-- begin::Company -->
      <template v-if="company">
        <li class="menu-section">
          <h4 class="menu-text text-white">{{ company.name }}</h4>
          <i class="menu-icon flaticon-more-v2"></i>
        </li>

        <router-link
          to="/dashboard"
          v-slot="{ href, navigate, isActive, isExactActive }"
          custom
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-icon">
                <font-awesome-icon :icon="['fas', 'home']" />
              </i>
              <span class="menu-text">Dashboard</span>
            </a>
          </li>
        </router-link>

        <router-link
          v-if="$auth.hasPermission('equipment.get')"
          to="/sunbeds"
          v-slot="{ href, navigate, isActive, isExactActive }"
          custom
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-icon">
                <font-awesome-icon :icon="['fas', 'tags']" />
              </i>
              <span class="menu-text">Sunbeds</span>
            </a>
          </li>
        </router-link>

        <router-link
          v-if="$auth.hasPermission('equipment.statistics.overall_errors')"
          to="/statistics/sunbeds/errors"
          v-slot="{ href, navigate, isActive, isExactActive }"
          custom
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-icon">
                <font-awesome-icon :icon="['fas', 'exclamation-circle']" />
              </i>
              <span class="menu-text">Sunbed errors</span>
            </a>
          </li>
        </router-link>

        <router-link
          v-if="$auth.hasPermission('companies.notifications.preferences.get') || $auth.hasPermission('companies.notifications.settings.get')"
          to="/notifications"
          v-slot="{ href, navigate, isActive, isExactActive }"
          custom
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-icon">
                <font-awesome-icon :icon="['fas', 'bell']" />
              </i>
              <span class="menu-text">Notifications</span>
            </a>
          </li>
        </router-link>

        <router-link
          v-if="$auth.hasPermission('users.get')"
          to="/users"
          v-slot="{ href, navigate, isActive, isExactActive }"
          custom
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-icon">
                <font-awesome-icon :icon="['fas', 'users']" />
              </i>
              <span class="menu-text">Users</span>
            </a>
          </li>
        </router-link>

        <router-link
          v-if="$auth.hasPermission('companies.get')"
          to="/companies"
          v-slot="{ href, navigate, isActive, isExactActive }"
          custom
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-icon">
                <font-awesome-icon :icon="['fas', 'store-alt']" />
              </i>
              <span class="menu-text">Companies</span>
            </a>
          </li>
        </router-link>

        <router-link
          to="/settings/company"
          v-slot="{ href, navigate, isActive, isExactActive }"
          custom
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-icon">
                <font-awesome-icon :icon="['fas', 'cogs']" />
              </i>
              <span class="menu-text">Settings</span>
            </a>
          </li>
        </router-link>

        <!-- begin::Company - Admin -->
        <template v-if="company && ($auth.hasPermission('updates.get') || $auth.hasPermission('devices.get'))">
          <li class="menu-section">
            <h4 class="menu-text text-white">Admin</h4>
            <i class="menu-icon flaticon-more-v2"></i>
          </li>

          <router-link
            v-if="$auth.hasPermission('updates.get')"
            to="/admin/updates"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon">
                  <font-awesome-icon :icon="['fas', 'cloud-upload-alt']" />
                </i>
                <span class="menu-text">Updates</span>
              </a>
            </li>
          </router-link>

          <router-link
            v-if="$auth.hasPermission('devices.get')"
            to="/admin/modules"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon">
                  <font-awesome-icon :icon="['fas', 'cubes']" />
                </i>
                <span class="menu-text">Modules</span>
              </a>
            </li>
          </router-link>
        </template>
        <!-- end::Company - Admin -->
      </template>
      <!-- end::Company -->

      <!-- begin::Support -->
      <li class="menu-section">
        <h4 class="menu-text text-white">Support</h4>
        <i class="menu-icon flaticon-more-v2"></i>
      </li>

      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
      >
        <a href="https://support.vdlhapro.com/hc/en-150/categories/360003415878" target="_blank" class="menu-link">
          <i class="menu-icon">
            <font-awesome-icon :icon="['fas', 'question-circle']" />
          </i>
          <span class="menu-text">Frequently Asked Questions</span>
        </a>
      </li>
      <!-- end::Support -->
    </template>
    <!-- end::Resource - User -->
  </ul>
</template>

<script>
import { CompanyClass as Company } from '@vedicium/core-vue/build/modules/company';

export default {
  name: 'KTMenu',
  props: {
    company: {
      type: Company,
      default: null,
    },
  },
  methods: {
    hasActiveChildren (match) {
      return this.$route.path.indexOf(match) !== -1;
    },
  },
};
</script>
