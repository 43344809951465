import scss from '@/assets/sass/layout/index.scss';

export default {
  self: {
    layout: 'default',
    logo: 'assets/images/vdl-group-logo.png',
  },
  plugins: {
    fontAwesome: {
      prefix: 'fas',
    },
  },
  loader: {
    type: 'spinner-logo',
    logo: 'media/logos/logo-letter-1.png',
    message: 'Please wait...',
  },
  breakpoints: {
    xs: (parseInt(scss['breakpoint-xs'], 10) || 0),
    sm: (parseInt(scss['breakpoint-sm'], 10) || 576),
    md: (parseInt(scss['breakpoint-md'], 10) || 768),
    lg: (parseInt(scss['breakpoint-lg'], 10) || 992),
    xl: (parseInt(scss['breakpoint-xl'], 10) || 1200),
    xxl: (parseInt(scss['breakpoint-xxl'], 10) || 1200),
  },
  colors: {
    theme: {
      base: {
        white: (scss['theme-color-white'] || '#ffffff'),
        primary: (scss['theme-color-primary'] || '#6993FF'),
        secondary: (scss['theme-color-secondary'] || '#E5EAEE'),
        success: (scss['theme-color-success'] || '#1BC5BD'),
        info: (scss['theme-color-info'] || '#8950FC'),
        warning: (scss['theme-color-warning'] || '#FFA800'),
        danger: (scss['theme-color-danger'] || '#F64E60'),
        light: (scss['theme-color-light'] || '#F3F6F9'),
        dark: (scss['theme-color-dark'] || '#212121'),
        brand: (scss['theme-color-brand'] || '#6993FF'),
      },
      light: {
        white: (scss['theme-color-white-light'] || '#ffffff'),
        primary: (scss['theme-color-primary-light'] || '#E1E9FF'),
        secondary: (scss['theme-color-secondary-light'] || '#ECF0F3'),
        success: (scss['theme-color-success-light'] || '#C9F7F5'),
        info: (scss['theme-color-info-light'] || '#EEE5FF'),
        warning: (scss['theme-color-warning-light'] || '#FFF4DE'),
        danger: (scss['theme-color-danger-light'] || '#FFE2E5'),
        light: (scss['theme-color-light-light'] || '#F3F6F9'),
        dark: (scss['theme-color-dark-light'] || '#D6D6E0'),
        brand: (scss['theme-color-brand-light'] || '#E1E9FF'),
      },
      inverse: {
        white: (scss['theme-color-white-inverse'] || '#ffffff'),
        primary: (scss['theme-color-primary-inverse'] || '#ffffff'),
        secondary: (scss['theme-color-secondary-inverse'] || '#212121'),
        success: (scss['theme-color-success-inverse'] || '#ffffff'),
        info: (scss['theme-color-info-inverse'] || '#ffffff'),
        warning: (scss['theme-color-warning-inverse'] || '#ffffff'),
        danger: (scss['theme-color-danger-inverse'] || '#ffffff'),
        light: (scss['theme-color-light-inverse'] || '#464E5F'),
        dark: (scss['theme-color-dark-inverse'] || '#ffffff'),
        brand: (scss['theme-color-brand-inverse'] || '#ffffff'),
      },
    },
    gray: {
      'gray-100': (scss['gray-100'] || '#F3F6F9'),
      'gray-200': (scss['gray-200'] || '#ECF0F3'),
      'gray-300': (scss['gray-300'] || '#E5EAEE'),
      'gray-400': (scss['gray-400'] || '#D6D6E0'),
      'gray-500': (scss['gray-500'] || '#B5B5C3'),
      'gray-600': (scss['gray-600'] || '#80808F'),
      'gray-700': (scss['gray-700'] || '#464E5F'),
      'gray-800': (scss['gray-800'] || '#1B283F'),
      'gray-900': (scss['gray-900'] || '#212121'),
    },
  },
  'font-family': (scss['font-family-base'] || 'Poppins'),
  header: {
    self: {
      display: true,
      width: 'fluid',
      theme: 'light',
      fixed: {
        desktop: true,
        mobile: true,
      },
    },
    menu: {
      self: {
        display: true,
        layout: 'default',
        'root-arrow': false,
      },
      desktop: {
        arrow: true,
        toggle: 'click',
        submenu: {
          theme: 'light',
          arrow: true,
        },
      },
      mobile: {
        submenu: {
          theme: 'dark',
          accordion: true,
        },
      },
    },
  },
  subheader: {
    display: true,
    displayDesc: false,
    displayDaterangepicker: true,
    layout: 'subheader-v1',
    fixed: true,
    width: 'fluid',
    clear: false,
    style: 'solid',
  },
  content: {
    width: 'fluid',
  },
  brand: {
    self: {
      theme () {
        // eslint-disable-next-line no-unused-expressions
        import('@/assets/sass/layout/brand.scss');
      },
    },
  },
  aside: {
    self: {
      theme () {
        // eslint-disable-next-line no-unused-expressions
        import('@/assets/sass/layout/aside.scss');
      },
      display: true,
      fixed: true,
      minimize: {
        toggle: true,
        default: true,
        hoverable: true,
      },
    },
    footer: {
      self: {
        display: false,
      },
    },
    menu: {
      dropdown: false,
      scroll: false,
      submenu: {
        accordion: true,
        dropdown: {
          arrow: true,
          'hover-timeout': 500,
        },
      },
    },
  },
  footer: {
    width: 'fluid',
    fixed: false,
    display: true,
  },

  mobile: {
    menu: {
      aside: {
        display: true,
      },
      header: {
        display: false,
      },
      topbar: {
        display: true,
        icon: ['fas', 'user'],
      },
    },
  },
};
