<template>
  <b-modal
    ref="modal"

    title="Company invite"

    size="lg"
    centered

    :no-close-on-esc="(options && options.required === true)"
    :no-close-on-backdrop="(options && options.required === true)"
    :hide-header-close="(options && options.required === true)"
    :hide-footer="(options && options.required === true)"
  >
    <template v-slot:default>
      <!-- begin::Loading -->
      <template v-if="isLoading">
        <div class="row">
          <div class="col-12 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
            <div class="loading-container">
              <div class="loading-block">
                <div class="blockui ml-auto mr-auto w-100">
                  <span class="float-left">Gathering data...</span>
                  <span class="spinner spinner-primary ml-auto" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <!-- end::Loading -->

      <template v-else>
        <!-- begin::Invite not found -->
        <div class="row" v-if="isErrorActive">
          <error-translation-alert
            ref="errorAlert"
            :error="activeError"
            class="col"
          />
        </div>
        <!-- end::Invite not found -->

        <!-- begin::Invite -->
        <div class="row" v-else>
          <div class="col alert alert-custom alert-light-info my-0" role="alert">
            <div class="alert-icon">
              <i>
                <font-awesome-icon :icon="['fas', 'envelope']" />
              </i>
            </div>
            <div class="alert-text">
              <span class="font-weight-bold d-block">{{ companyInvite.company.name }}</span>
              <span class="d-block">
                You've been invited to join.
              </span>
            </div>
          </div>
        </div>
        <!-- end::Invite -->
      </template>
    </template>

    <template v-slot:modal-footer>
      <button type="button" :class="['btn btn-secondary', { 'mr-auto': companyInvite }]" :disabled="isLoading" @click.prevent="close">Cancel</button>

      <template v-if="companyInvite">
        <button type="button" class="btn btn-danger" :disabled="isLoading" @click.prevent="declineInvite()">Decline</button>
        <button type="button" class="btn btn-success" :disabled="isLoading" @click.prevent="acceptInvite()">Accept</button>
      </template>
    </template>
  </b-modal>
</template>

<script>
import { Mixins } from '@vedicium/metronic-vue';
import { errorComponentMixin } from '@vedicium/core-vue';
import { CompanyInviteClass as CompanyInvite } from '@vedicium/core-vue/build/modules/company';
import errorTranslationAlert from '@/components/errors/translation.alert.vue';

export default {
  name: 'companyInviteModal',
  mixins: [Mixins.Modal, errorComponentMixin],
  components: {
    errorTranslationAlert,
  },
  data () {
    return {
      options: null,
      isLoading: false,
      isError: null,

      companyInvite: null,
    };
  },

  mounted () {
    // Listeners
    this.$eventhub.on('modals:layout:company-invite:open', this.open);
    this.$eventhub.on('modals:layout:company-invite:close', this.close);
  },
  beforeDestroy () {
    // Remove listeners
    this.$eventhub.off('modals:layout:company-invite:open', this.open);
    this.$eventhub.off('modals:layout:company-invite:close', this.close);
  },

  methods: {
    async getCompanyInvite () {
      this.$set(this, 'isLoading', true);
      this.$set(this, 'companyInvite', null);

      try {
        this.$set(this, 'companyInvite', await CompanyInvite.get(this.options.token));
      } catch (e) {
        console.error(e);
        this.$errors.handle(e, { component: this, ui_element: false });
        throw e;
      } finally {
        this.$set(this, 'isLoading', false);
      }

      return this.companyInvite;
    },
    async acceptInvite () {
      this.$set(this, 'isLoading', true);

      try {
        this.$set(this, 'companyInvite', await this.companyInvite.accept());

        // Run 'onSubmit'
        if (this.options && typeof this.options.onSubmit === 'function') {
          await this.options.onSubmit(this.companyInvite);
        }
      } catch (e) {
        this.$errors.handle(e, { component: this });
        console.error(e);
        throw e;
      } finally {
        this.$set(this, 'isLoading', false);
      }

      this.close();
    },
    async declineInvite () {
      this.$set(this, 'isLoading', true);

      try {
        this.$set(this, 'companyInvite', await this.companyInvite.decline());

        if (this.options && typeof this.options.onSubmit === 'function') {
          await this.options.onSubmit(this.companyInvite);
        }
      } catch (e) {
        this.$errors.handle(e, { component: this });
        console.error(e);
        throw e;
      } finally {
        this.$set(this, 'isLoading', false);
      }

      this.close();
    },

    open (options = {}) {
      // Reset options
      this.$set(this, 'options', options);

      this.getCompanyInvite();

      this.$refs.modal.show();
    },
    close () {
      // Remove invite query
      if (this.$route.query.invite) {
        this.$router.replace({
          ...this.$router.currentRoute,
          query: {
            ...this.$router.currentRoute.query,
            invite: undefined,
          },
        });
      }

      this.$refs.modal.hide();
    },

    onShown () {

    },
  },
};
</script>
