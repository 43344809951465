<template>
  <b-modal
    ref="modal"

    :title="(options.title || 'Modify field')"

    size="lg"
    centered
  >
    <template v-slot:default>
      <form class="form" @submit.prevent="submitForm">
        <div class="form-group">
          <label>{{ (options.label || 'Field') }} <sup v-if="options.required === true">Required</sup></label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i>
                  <font-awesome-icon :icon="(options.label_icon || ['fas', 'text'])" />
                </i>
              </span>
            </div>
            <input
              ref="fieldInput"
              type="text"
              :class="['form-control', { 'is-invalid': $v.form.value.$error }]"
              :disabled="isLoadingForm"
              v-model.trim="$v.form.value.$model"
            />
          </div>
          <span v-if="options.subtext" class="form-text text-muted">{{ options.subtext }}</span>
        </div>
      </form>
    </template>

    <template v-slot:modal-footer>
      <button type="button" class="btn btn-secondary" :disabled="isLoadingForm" @click.prevent="close">Cancel</button>
      <button type="submit" class="btn btn-primary" :disabled="isLoadingForm" @click.prevent="submitForm">Modify</button>
    </template>
  </b-modal>
</template>

<script>
import { requiredIf } from 'vuelidate/lib/validators';
import { validations } from '@vedicium/core-vue';
import { Mixins } from '@vedicium/metronic-vue';

export default {
  mixins: [Mixins.Modal],
  name: 'modifyFieldModal',
  data () {
    return {
      options: {},
      isLoadingForm: null,

      form: {
        value: null,
      },
    };
  },
  validations () {
    return {
      form: {
        value: {
          // eslint-disable-next-line func-names
          required: requiredIf(function () {
            return this.options && this.options.required === true;
          }),

          ...(this.options.validators || {}),
        },
      },
    };
  },

  mounted () {
    // Listeners
    this.$eventhub.on('modals:layout:document:modify-field:open', this.open);
    this.$eventhub.on('modals:layout:document:modify-field:close', this.close);
  },
  beforeDestroy () {
    // Remove listeners
    this.$eventhub.off('modals:layout:document:modify-field:open', this.open);
    this.$eventhub.off('modals:layout:document:modify-field:close', this.close);
  },

  methods: {
    async validate () {
      await validations.validateArray([this.$v]);
    },
    async submitForm () {
      this.$set(this, 'isLoadingForm', true);

      try {
        await this.validate();

        if (this.options && typeof this.options.onSubmit === 'function') {
          await this.options.onSubmit(this.form.value);
        }
      } catch (e) {
        this.$errors.handle(e);
        console.error(e);
        return;
      } finally {
        this.$set(this, 'isLoadingForm', false);
      }

      this.close();
    },

    open (options = {}) {
      // Reset options
      this.$set(this, 'options', options || {});

      // Reset form
      this.$set(this.form, 'value', options.value || null);
      this.$v.$reset();

      this.$refs.modal.show();
    },
    close () {
      this.$refs.modal.hide();
    },

    async onShown () {
      await this.$nextTick();

      this.$refs.fieldInput.focus();
    },
    onHide () {
      this.$errors.clear();
    },
  },
};
</script>
